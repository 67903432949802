import SearchPhoneIcon from '../images/SearchPhone.svg'
import {
  aboutLink,
  blogLink,
  mediaLink,
  // authLink,
} from './menu.context'
// import AvatarIcon from '../images/Avatar.svg'
// import generateUserAccountPath from '../utils/generateUserAccountPath'

export type MenuItem = {
  label: string
  position?: 'left' | 'right' | 'borderRight'
  icon?: string
  nested?: boolean
  image?: string
  url: string
  type?: 'models' | 'brands'
  other?: MenuItem
  items?: MenuItem[]
  hideOnMobile?: true
}

export const reparationLink = '/reparation/'
export const servicesLink = '/services/'

export const servicesLinks = {
  recover: `${servicesLink}protection-recover/`,
  accessoires: `${servicesLink}accessoires-smartphone/`,
  reconditionnes: `${servicesLink}smartphones-reconditionnes/`,
  pret: `${servicesLink}pret-smartphone/`,
  rachat: `${servicesLink}reprise-recyclage/`,
  backup: `${servicesLink}sauvegarde-de-donnees/`,
} as const

export const menuServiceItems: MenuItem[] = [
  { label: 'reCover', url: servicesLinks.recover },
  { label: 'Accessoires', url: servicesLinks.accessoires },
  { label: 'Smartphones reconditionnés', url: servicesLinks.reconditionnes },
  { label: 'Prêt si immobilisation', url: servicesLinks.pret },
  { label: 'Reprise & recyclage', url: servicesLinks.rachat },
  { label: 'Sauvegarde & transfert de données', url: servicesLinks.backup },
]

export const magasinLink = 'https://magasin.save.co/'
export const actualiteLink = `${blogLink}actualites-save/`
export const contactLink = `${aboutLink}contact/`

export type Menu = MenuItem[]

const menu: Menu = [
  // see src/hooks/useMenu.ts
  { label: 'Magasins', position: 'left', url: magasinLink },
  {
    label: 'Nos services',
    position: 'left',
    url: servicesLink,
    image: `${process.env.GATSBY_SITE_URL}images/service_smartphone.jpg`,
    items: menuServiceItems,
  },
  { label: 'L’astucerie', position: 'right', url: blogLink },
  {
    label: 'À propos',
    position: 'right',
    url: aboutLink,
    image: `${process.env.GATSBY_SITE_URL}images/magasin_save.jpg`,
    items: [
      { label: 'Qui sommes-nous ?', url: aboutLink },
      {
        label: 'Devenir franchisé Save',
        url: `${aboutLink}devenir-franchise-save/`,
      },
      { label: 'Partenaires', url: `${aboutLink}partenaires/` },
      { label: 'Recrutement', url: `${aboutLink}recrutement/` },
      { label: 'Média', url: mediaLink },
      { label: 'Nous contacter', url: contactLink },
      { label: 'FAQ', url: `${aboutLink}faq/` },
    ],
  },
]

export default menu
