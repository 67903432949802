import React from 'react'
import styled, { css } from 'styled-components'
import FooterBackground from './FooterBackground'
import SocialLinks from './SocialLinks'
import isExternalLink from '../utils/isExternalLink'
import useFooterMenu from '../hooks/useFooterMenu'
import LinkExtended from './LinkExtended'
import ResponsiveOrder from './ResponsiveOrder'
import highlights from '../data/highlights'
import tileLinks from '../data/tileLinks'
import Highlights from './Highlights'
import HomeMap from './HomeMap'
import TileLinks from './TileLinks'
import Tiles from './Tiles'

const Container = styled.div`
  font-family: GothamRounded;
  width: 100vw;

  @media print {
    display: none !important;
  }
`

const FooterWrapper = styled.div`
  max-width: calc(${({ theme }) => theme.container.maxWidth} - 2 * 70px);
  padding: 0 18px;
  margin: 0 auto;
  color: white;
`

const ImageContainer = styled.span`
  display: inline-flex;
  overflow: hidden;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 54px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 0;
  }

  & > * {
    margin-top: -10px;
    width: 173px;
    height: 173px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: -40px;
      width: 387px;
      height: 387px;
    }
  }
`

const Copyright = styled.div`
  display: none;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  padding: 33px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`

const title = css`
  font-style: normal;
  font-weight: bold;
  display: inline-block;
  font-size: 18px;
  line-height: 22px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 22px;
    line-height: 26px;
  }
`

const titleLink = css`
  ${title}
  padding-bottom: 2px;
  margin-bottom: 5px;
  color: #fff;
  text-decoration: none;
  transition: all 300ms ease;
  display: block;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`

const TitleLink = styled(LinkExtended)`
  ${titleLink}
`

const TitleExternalLink = styled.a`
  ${titleLink}
`

const TitleFollow = styled.span`
  ${title}
  margin-bottom: 16px;
`

const Links = styled.ul`
  display: flex;
  flex-direction: column;
`

const link = css`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.muted};
  text-decoration: none;
  display: block;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 0px;
  transition: background-color 300ms ease;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 18px;
    line-height: 21px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`

const LinkWrapper = styled(LinkExtended)`
  ${link}
`

const ExternalLinkWrapper = styled.a`
  ${link}
`

const List = styled.ul`
  display: grid;
  margin-top: 34px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 27px;
`

const Follow = styled.div`
  margin-top: 34px;
  padding-bottom: 48px;
`

const Footer: React.FC = () => {
  const footer = useFooterMenu()

  return (
    <Container>
      <FooterBackground>
        <FooterWrapper>
          <ResponsiveOrder>
            <Highlights items={highlights} />
          </ResponsiveOrder>
          <List>
            {footer.map((item, index) => (
              <li key={index}>
                {isExternalLink(item.url) ? (
                  <TitleExternalLink href={item.url}>
                    {item.label}
                  </TitleExternalLink>
                ) : (
                  <TitleLink to={item.url} noprefetch>
                    {item.label}
                  </TitleLink>
                )}

                <Links>
                  {item.items.map((subItem, subIndex) => (
                    <li key={subIndex}>
                      {isExternalLink(subItem.url) ? (
                        <ExternalLinkWrapper href={subItem.url}>
                          {subItem.label}
                        </ExternalLinkWrapper>
                      ) : (
                        <LinkWrapper to={subItem.url} noprefetch>
                          {subItem.label}
                        </LinkWrapper>
                      )}
                    </li>
                  ))}
                </Links>
              </li>
            ))}
          </List>

          <Follow>
            <TitleFollow>Nous suivre</TitleFollow>
            <SocialLinks />
          </Follow>
          <Copyright>Save-{new Date().getFullYear()}</Copyright>
        </FooterWrapper>
      </FooterBackground>
    </Container>
  )
}

export default Footer
