import { useMemo } from 'react'
import menu, { MenuItem, Menu } from '../data/menu'
import { MENU_MAX_PRIMARY_BRANDS, reparationsLinks } from '../data/menu.context'
import { repairBrandPageBuilder } from '../templates/RepairBrandPage.context'
import { repairModelPageBuilder } from '../templates/RepairModelPage.context'
import notEmpty from '../utils/notEmpty'
import useSaveMenuQuery from './useSaveMenuQuery'
import capitalizeFirstLetter from '../utils/capitalizeFirstLetter'

const useMenu = (): Menu => {
  const saveMenu = useSaveMenuQuery()

  return useMemo(() => {
    const brands = saveMenu?.brands?.filter(notEmpty) ?? []
    const models = saveMenu?.models?.filter(notEmpty) ?? []

    const primaryBrands = brands.slice(0, MENU_MAX_PRIMARY_BRANDS)
    const secondaryBrands = brands.slice(MENU_MAX_PRIMARY_BRANDS)

    const repairItem: MenuItem = {
      label: 'Réparation',
      position: 'left',
      url: reparationsLinks.all,
      nested: true,
      image: `${process.env.GATSBY_SITE_URL}images/reparation_smartphone.jpg`,
      items: [
        // mains
        ...primaryBrands.map((brand) => {
          const item: MenuItem = {
            label: `Réparation ${capitalizeFirstLetter(brand.name)}`,
            url: repairBrandPageBuilder(brand.name ?? ''),
            type: 'models',
            other: {
              label: 'Autres modèles',
              url: repairBrandPageBuilder(brand.name ?? ''),
            },
            items: models
              .filter((x) => x.brandName === brand.name)
              .map((model) => ({
                label: capitalizeFirstLetter(model.name),
                url: repairModelPageBuilder(
                  brand.name ?? '',
                  model.slugFull ?? ''
                ),
              })),
          }
          return item
        }),
        // others
        {
          label: 'Réparation Autres',
          url: reparationsLinks.all,
          type: 'brands',
          other: {
            label: 'Autres marques',
            url: reparationsLinks.other,
          },
          items: secondaryBrands.map((brand) => ({
            label: capitalizeFirstLetter(brand.name),
            url: repairBrandPageBuilder(brand.name ?? ''),
          })),
        },
      ],
    }

    return [repairItem, ...menu]
  }, [saveMenu])
}

export default useMenu
