import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { BreadcrumbItems, IBreadcrumbItem } from './Breadcrumb.context'
import { WindowLocation } from '@reach/router'

const BreadcrumbList = styled.ul`
  background: white;
  display: flex;
  flex-wrap: wrap;
  padding: 7px 19px;
  gap: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 16px 20px 16px 30px;
    font-size: 13px;
    line-height: 15px;
  }

  @media print {
    display: none !important;
  }
`

interface BreadcrumbItemProps {
  home?: boolean
  emptyLabel?: boolean
}

const BreadcrumbItem = styled.li<BreadcrumbItemProps>`
  display: inline-flex;
  align-items: center;

  &:not(:last-child) {
    &:after {
      content: '/';
      margin-left: 5px;
      color: ${({ theme }) => theme.colors.body};
    }
  }

  ${({ emptyLabel }) => {
    return (
      emptyLabel &&
      css`
        margin-right: -5px;

        &:after {
          content: '';
          display: none;
        }
      `
    )
  }}
`

interface BreadcrumbLinkProps {
  home?: string
}

const arrowLeft = css`
  overflow: hidden;
  display: inline-block;
  background-image: url('/images/ArrowLeft.svg');
  background-size: auto;
  background-position: center left;
  background-repeat: no-repeat;
  padding-left: 20px;
  height: 100%;
`

const link = css<BreadcrumbLinkProps>`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.secondary};
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;

  ${({ home }) => home && arrowLeft}
`

const BreadcrumbLink = styled(Link)<BreadcrumbLinkProps>`
  ${link}
`

const BreadcrumbButton = styled.button<BreadcrumbLinkProps>`
  ${link}
`

const BreadcrumbSpan = styled.span<BreadcrumbLinkProps>`
  color: ${({ theme }) => theme.colors.body};

  ${({ home }) => home && arrowLeft}
`

const homeItem: IBreadcrumbItem = { label: '', href: '/' }

interface BreadcrumbProps {
  items: BreadcrumbItems | undefined
  location?: WindowLocation<unknown>
  title?: string
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  const breadcrumbs = useMemo(
    () =>
      items ? (items.length === 1 ? [homeItem, ...items] : items) : undefined,
    [items]
  )

  if (!breadcrumbs) {
    return <></>
  }

  return (
    <BreadcrumbList>
      {breadcrumbs.map((item, index) => (
        <BreadcrumbItem
          key={index}
          home={index === 0}
          emptyLabel={item.label.trim().length === 0}
        >
          {typeof item.href == 'string' ? (
            <BreadcrumbLink
              to={item.href}
              home={index === 0 ? 'true' : undefined}
            >
              {item.label}
            </BreadcrumbLink>
          ) : item.href ? (
            <BreadcrumbButton
              onClick={item.href}
              home={index === 0 ? 'true' : undefined}
            >
              {item.label}
            </BreadcrumbButton>
          ) : (
            <BreadcrumbSpan home={index === 0 ? 'true' : undefined}>
              {item.label}
            </BreadcrumbSpan>
          )}
        </BreadcrumbItem>
      ))}
    </BreadcrumbList>
  )
}

export default Breadcrumb
