export const MENU_MAX_MODELS = 9
export const MENU_MAX_PRIMARY_BRANDS = 3
export const MENU_MAX_BRANDS = MENU_MAX_PRIMARY_BRANDS + MENU_MAX_MODELS
export const aboutLink = '/apropos/'
export const authLink = '/identification/'
export const blogLink = '/blog/'
export const mediaLink = `${aboutLink}media/`
export const repairOrderLink = '/bon-reparation/'
export const smartphoneSendLink = '/envoi-smartphone/'
export const paymentDoneLink = '/paiement-effectue/'

export const reparationsLinks = {
  other: '/devis/',
  all: '/reparation/',
}
