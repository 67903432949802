import { AnimatePresence, motion } from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { RepairStepsContainerProps } from './RepairStepsContainer.context'
import {
  repairStepsContainerHeroClassName,
  translateX,
} from './RepairStepsContainer.nostyle'

const Container = styled.div`
  background-color: #fff;
  padding: 4px 0 12px 0;
  position: relative;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-height: 170px;
    padding: 10px 0 0 0;
  }

  & > .${repairStepsContainerHeroClassName} {
    position: absolute;
    bottom: -30px;
    left: 50%;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: none;
    }
  }

  & > *[class^='RepairSteps'] {
    max-width: 328px;
    margin: 0 auto;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      max-width: 552px;
    }
  }
`

const RepairStepsContainer: React.FC<RepairStepsContainerProps> = ({
  show,
  showHero,
  children,
}) => {
  if (!show) {
    return <></>
  }

  return (
    <Container>
      <AnimatePresence initial={false}>
        {showHero && (
          <motion.div
            initial={{ x: translateX, y: 186 }}
            animate={{ x: translateX, y: 0 }}
            exit={{ x: translateX, y: 186 }}
            className={repairStepsContainerHeroClassName}
            transition={{ type: 'ease' }}
          >
            <StaticImage
              src="../../static/images/HeroPersonDesktop.png"
              alt=""
              width={155}
              height={186}
              placeholder="blurred"
              quality={100}
            />
          </motion.div>
        )}
      </AnimatePresence>

      {children}
    </Container>
  )
}

export default RepairStepsContainer
