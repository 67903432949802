import { RepairsQueryVariables } from '../_generated/codegen/graphqlTypes'
import { repairBrandPageBuilder } from './RepairBrandPage.context'

export type RepairModelPageContext = {
  brandName: string
  slug: string
  name: string
} & RepairsQueryVariables

export const repairModelPageBuilder = (brandName: string, slug: string) => {
  return `${repairBrandPageBuilder(brandName)}${slug}/`
}
