import React from 'react'
import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
// import '@fontsource/roboto/100-italic.css'
// import '@fontsource/roboto/100.css'
// import '@fontsource/roboto/300-italic.css'
// import '@fontsource/roboto/300.css'
// import '@fontsource/roboto/400-italic.css'
import '@fontsource/roboto/400.css'
// import '@fontsource/roboto/500-italic.css'
import '@fontsource/roboto/500.css'
// import '@fontsource/roboto/700-italic.css'
import '@fontsource/roboto/700.css'
// import '@fontsource/roboto/900-italic.css'
// import '@fontsource/roboto/900.css'
import '../fonts/gotham-rounded/index.css'
import { Helmet } from 'react-helmet'
import { fadeUpWhenVisibleNoScriptStyle } from './FadeUpWhenVisible.nostyle'
import { aboutRowNoScriptStyle } from './AboutColumns.nostyle'
import { repairStepsContainerHeroNoScriptStyle } from './RepairStepsContainer.nostyle'

const GlobalStyleCSSInJS = createGlobalStyle`
  ${reset}

  * {
    box-sizing: border-box;
  }
  
  body {
    max-width: 100vw;
    overflow-x: hidden;
    font-family: GothamRounded;
    background-color: ${({ theme }) => theme.colors.background};
  }
`

interface GlobalStyleProps {
  scope?: string
}

const GlobalStyle: React.FC<GlobalStyleProps> = ({ scope }) => (
  <>
    <GlobalStyleCSSInJS />
    <Helmet>
      <noscript>
        {`
          <style>
            ${fadeUpWhenVisibleNoScriptStyle(scope)}
            ${aboutRowNoScriptStyle(scope)}
            ${repairStepsContainerHeroNoScriptStyle(scope)}
          </style>
        `}
      </noscript>
    </Helmet>
  </>
)

export default GlobalStyle
