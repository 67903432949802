// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apropos-contact-tsx": () => import("./../../../src/pages/apropos/contact.tsx" /* webpackChunkName: "component---src-pages-apropos-contact-tsx" */),
  "component---src-pages-apropos-devenir-franchise-save-tsx": () => import("./../../../src/pages/apropos/devenir-franchise-save.tsx" /* webpackChunkName: "component---src-pages-apropos-devenir-franchise-save-tsx" */),
  "component---src-pages-apropos-faq-tsx": () => import("./../../../src/pages/apropos/faq.tsx" /* webpackChunkName: "component---src-pages-apropos-faq-tsx" */),
  "component---src-pages-apropos-index-tsx": () => import("./../../../src/pages/apropos/index.tsx" /* webpackChunkName: "component---src-pages-apropos-index-tsx" */),
  "component---src-pages-apropos-partenaires-tsx": () => import("./../../../src/pages/apropos/partenaires.tsx" /* webpackChunkName: "component---src-pages-apropos-partenaires-tsx" */),
  "component---src-pages-apropos-recrutement-tsx": () => import("./../../../src/pages/apropos/recrutement.tsx" /* webpackChunkName: "component---src-pages-apropos-recrutement-tsx" */),
  "component---src-pages-devis-tsx": () => import("./../../../src/pages/devis.tsx" /* webpackChunkName: "component---src-pages-devis-tsx" */),
  "component---src-pages-external-contact-form-page-tsx": () => import("./../../../src/pages/ExternalContactFormPage.tsx" /* webpackChunkName: "component---src-pages-external-contact-form-page-tsx" */),
  "component---src-pages-identification-tsx": () => import("./../../../src/pages/identification.tsx" /* webpackChunkName: "component---src-pages-identification-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-reparation-tsx": () => import("./../../../src/pages/reparation.tsx" /* webpackChunkName: "component---src-pages-reparation-tsx" */),
  "component---src-pages-select-shop-tsx": () => import("./../../../src/pages/select-shop.tsx" /* webpackChunkName: "component---src-pages-select-shop-tsx" */),
  "component---src-pages-services-accessoires-smartphone-tsx": () => import("./../../../src/pages/services/accessoires-smartphone.tsx" /* webpackChunkName: "component---src-pages-services-accessoires-smartphone-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-pret-smartphone-tsx": () => import("./../../../src/pages/services/pret-smartphone.tsx" /* webpackChunkName: "component---src-pages-services-pret-smartphone-tsx" */),
  "component---src-pages-services-protection-recover-tsx": () => import("./../../../src/pages/services/protection-recover.tsx" /* webpackChunkName: "component---src-pages-services-protection-recover-tsx" */),
  "component---src-pages-services-reprise-recyclage-tsx": () => import("./../../../src/pages/services/reprise-recyclage.tsx" /* webpackChunkName: "component---src-pages-services-reprise-recyclage-tsx" */),
  "component---src-pages-services-sauvegarde-de-donnees-tsx": () => import("./../../../src/pages/services/sauvegarde-de-donnees.tsx" /* webpackChunkName: "component---src-pages-services-sauvegarde-de-donnees-tsx" */),
  "component---src-pages-services-smartphones-reconditionnes-tsx": () => import("./../../../src/pages/services/smartphones-reconditionnes.tsx" /* webpackChunkName: "component---src-pages-services-smartphones-reconditionnes-tsx" */),
  "component---src-templates-blog-home-page-tsx": () => import("./../../../src/templates/BlogHomePage.tsx" /* webpackChunkName: "component---src-templates-blog-home-page-tsx" */),
  "component---src-templates-media-page-tsx": () => import("./../../../src/templates/MediaPage.tsx" /* webpackChunkName: "component---src-templates-media-page-tsx" */),
  "component---src-templates-media-post-page-tsx": () => import("./../../../src/templates/MediaPostPage.tsx" /* webpackChunkName: "component---src-templates-media-post-page-tsx" */),
  "component---src-templates-post-page-tsx": () => import("./../../../src/templates/PostPage.tsx" /* webpackChunkName: "component---src-templates-post-page-tsx" */),
  "component---src-templates-repair-brand-page-tsx": () => import("./../../../src/templates/RepairBrandPage.tsx" /* webpackChunkName: "component---src-templates-repair-brand-page-tsx" */),
  "component---src-templates-repair-model-page-tsx": () => import("./../../../src/templates/RepairModelPage.tsx" /* webpackChunkName: "component---src-templates-repair-model-page-tsx" */),
  "component---src-templates-repair-shop-page-tsx": () => import("./../../../src/templates/RepairShopPage.tsx" /* webpackChunkName: "component---src-templates-repair-shop-page-tsx" */),
  "component---src-templates-repair-summary-page-tsx": () => import("./../../../src/templates/RepairSummaryPage.tsx" /* webpackChunkName: "component---src-templates-repair-summary-page-tsx" */),
  "component---src-templates-terms-page-tsx": () => import("./../../../src/templates/TermsPage.tsx" /* webpackChunkName: "component---src-templates-terms-page-tsx" */)
}

